import React, { useEffect } from 'react';
import ReactRoutes from './routes/HomeRoutes';

function App() {

  return (
    <main>
      <ReactRoutes/>
    </main>
  );
}

export default App;
